import * as React from 'react';
export default function useFocusWithin(ref) {
    const [focusWithin, setFocusWithin] = React.useState(false);
    // Ensure typeahead results show when focus within container.
    React.useEffect(() => {
        ref.current?.addEventListener('focus', onFocusChange, true);
        ref.current?.addEventListener('blur', onBlur, true);
        return () => {
            ref.current?.removeEventListener('focus', onFocusChange);
            ref.current?.removeEventListener('blur', onBlur);
        };
    }, []);
    return focusWithin;
    function onFocusChange() {
        if (!ref.current) {
            return setFocusWithin(false);
        }
        if (!document.activeElement) {
            return setFocusWithin(false);
        }
        const isActive = ref.current.contains(document.activeElement);
        return setFocusWithin(isActive);
    }
    function onBlur(event) {
        if (!ref.current) {
            return setFocusWithin(false);
        }
        if (!event.relatedTarget) {
            return setFocusWithin(false);
        }
        const isActive = ref.current.contains(event.relatedTarget);
        return setFocusWithin(isActive);
    }
}
