const CLOUD_NAME = 'vp-groundforce';
export function imageUrl(publicId, params) {
    const transformation = createTransformation(params);
    const encodedPublicId = encodeURIComponent(publicId);
    return `https://res.cloudinary.com/${CLOUD_NAME}/image/upload/${transformation}/${encodedPublicId}`;
}
export function createTransformation(params) {
    let transformation = '';
    if (params.width) {
        transformation += `,w_${params.width}`;
    }
    if (params.height) {
        transformation += `,h_${params.height}`;
    }
    if (params.aspectRatio) {
        transformation += `,ar_${params.aspectRatio}`;
    }
    if (params.dpr) {
        transformation += `,dpr_${params.dpr}`;
    }
    if (params.crop) {
        transformation += `,c_${params.crop}`;
    }
    if (params.gravity) {
        transformation += `,g_${params.gravity}`;
    }
    const format = params.format ?? 'auto';
    transformation += `/f_${format},q_auto`;
    if (transformation.indexOf(',') === 0) {
        transformation = transformation.slice(1);
    }
    return transformation;
}
